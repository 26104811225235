<template>
    <div class="recruit">
        <img :src="am2vQQ" />
        <div class="recruit-content">
            <div class="recruit-name">{{details.position}}</div>
            <!-- <div class="recruit-duty">岗位职责：</div> -->
            <div v-html="details.introduce"></div>
        </div>
    </div>
</template>
<script>
import { am2vQQ } from '@/utils/imgUrl'
import { recruitmentView } from '@/api'
export default {
    name: 'recruit',
    data(){
        return{
            id: '',
            details: {}
        }
    },
    computed:{
        am2vQQ: () => am2vQQ,
    },
    created(){
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getRecruit()
        }
    },
    methods:{
        getRecruit(){
           recruitmentView(this.id).then(res => {
                console.log(res)
                this.details = res
           }) 
        }
    }
}
</script>
<style lang="scss" scoped>
.recruit{
    position: relative;
}
.recruit-content{
    position: absolute;
    width: 50%;
    top: 50%; 
    left: calc(50% / 2);
}
.recruit-name{
    font-size: 30px;
    color: rgb(36, 170, 175);
    margin-bottom: 40px;
}
.recruit-duty{
    font-size: 20px;
    font-weight: bold;
    color: #2b2b2b;
    margin-bottom: 20px;
}
</style>